import { Header } from '../Header'
import { AddCourseForm } from './components/AddCourseForm'

import './components/index.scss'

interface IProps {
  patientId: string
}

// This component is a placeholder, so that the form can load independently of the header component.
const AddCourse: React.FC<IProps> = ({ patientId }) => {
  return (
    <>
      <Header patientId={patientId} isOnline={false} showEditButton />
      <AddCourseForm patientId={patientId} />
    </>
  )
}

export { AddCourse }
