import { makeAutoObservable } from 'mobx'

import { dosingRecommendationPostUrl, dosingRecommendationRXBEPostUrl } from '../../constants/api'
import axiosClient, { AxiosError, AxiosResponse } from '../../utils/axiosClient'
import { RootStore } from '../RootStore'
import { TLoadState } from '../types'
import { DosingRecommendation } from './DosingRecommendation'
import {
  IComputedOutcomesPerDose,
  IDosingRecommendation,
  IDrugSpecificAttr,
  TModelType,
  TSimulationType,
  TStoreSliceType,
  ErrorDetails,
  IPOSTDrugSpecificAttr
} from './types'
import { normalizeAuc } from './utils'
import { roundDecimal } from '../../utils/numbers'
import { TValidDosingAttributes } from '../../types/doseReport'

export class DosingRecommendationStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: Record<TModelType, string> = {
    indPop: '',
    customDose: '',
    customTarget: '',
    guideline: ''
  }
  dosingRecommendation: Record<TModelType, DosingRecommendation | null> = {
    indPop: null,
    customDose: null,
    customTarget: null,
    guideline: null
  }

  nextDoseTimeOfLastCalculation: string | null = null

  customTargetCalculationValid: boolean = false
  customDoseCalculationValid: boolean = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setNextDoseTimeOfLastCalculation(nextDoseTime: string) {
    this.nextDoseTimeOfLastCalculation = nextDoseTime
  }

  setModelPayload(changeset: Partial<Record<TModelType, IDosingRecommendation>>, type: TStoreSliceType) {
    const applyChanges = Object.keys(changeset).reduce((acc, curr) => {
      const key = curr as TModelType

      return {
        ...acc,
        [key]: changeset[key] ? new DosingRecommendation(this, changeset[key]!) : null
      }
    }, this[type])

    this[type] = {
      ...this[type],
      ...applyChanges
    }
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setCustomTargetCalculationValid(is: boolean) {
    this.customTargetCalculationValid = is
  }

  setCustomDoseCalculationValid(is: boolean) {
    this.customDoseCalculationValid = is
  }

  setErrors(errorState: 'loadError' | 'updateError' | null, error: string, modelTypes: TModelType[]) {
    // all if none specified
    const setKeys: TModelType[] = modelTypes

    const commitErrors = (c: object, e: string | null) =>
      setKeys.reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: e
        }
      }, c)

    this.error = {
      ...this.error,
      ...commitErrors(this.error, error)
    }

    if (errorState) {
      this.setLoadState(errorState)
    }
  }

  resetPredictedData(selectedSimulationPanelTab?: TModelType) {
    if (selectedSimulationPanelTab) {
      this.dosingRecommendation[selectedSimulationPanelTab] = null
    } else {
      this.dosingRecommendation = {
        indPop: null,
        customDose: null,
        customTarget: null,
        guideline: null
      }
    }
  }

  hasPredictedData() {
    return this.loadState === 'loaded' &&
      Object.values(this.dosingRecommendation).some(x => x !== null)
  }

  resetStore() {
    this.dosingRecommendation = {
      indPop: null,
      customDose: null,
      customTarget: null,
      guideline: null
    }

    this.error = {
      indPop: '',
      customDose: '',
      customTarget: '',
      guideline: ''
    }

    this.setLoadState('initial')
  }

  parseModelSpecificErrors = (error: any, modelTypes: TModelType[]) => {
    if (error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
      // Error(s) from API
      const errors: ErrorDetails[] = error.response.data.errors.map((e: ErrorDetails) => {
        return { type: e.type, detail: e.detail, status: e.status }
      })

      errors.forEach((e) => {
        if (e.type) {
          this.error[e.type] = e.detail
        }

        if (!e.type) {
          this.setErrors('updateError', e.detail, modelTypes)
        }
      })
    }
  }

  getHistoricalOutcomes(selectedSimulationPanelTab: TModelType): IComputedOutcomesPerDose | null {
    const modelResultsTypes = this.dosingRecommendation[selectedSimulationPanelTab]?.attributes.modelResults

    if (!modelResultsTypes) {
      return null
    }

    const modelResults = modelResultsTypes.indPop || modelResultsTypes.customTarget || modelResultsTypes.customDose

    if (['loading', 'updating'].includes(this.loadState) || !modelResults?.historicalPerDoseOutcomes) {
      return null
    }

    return modelResults.historicalPerDoseOutcomes.reduce<IComputedOutcomesPerDose>((acc, curr) => {
      acc[curr.administrationId] = {
        auc: roundDecimal(curr.auc),
        auc24: normalizeAuc(curr.auc, curr.period),
        peak: roundDecimal(curr.peak),
        trough: roundDecimal(curr.trough),
        cumulative_auc: curr.cumulative_auc,
        cumulative_auc_percent_to_target: curr.cumulative_auc_percent_to_target
      }

      return acc
    }, {})
  }

  async fetchPredictedDosingRecommendation(
    patientId: string,
    courseId: string,
    drugModelId: string,
    type: TSimulationType,
    predictedAttributes: TValidDosingAttributes | null,
    modelTypes: TModelType[],
    nextDoseDate: string,
    drugSpecificAttributes: IDrugSpecificAttr | IPOSTDrugSpecificAttr
  ): Promise<void> {
    this.setLoadState('updating')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    const url = drugModelId === '54'
      ? dosingRecommendationRXBEPostUrl(patientId, courseId, drugModelId)
      : dosingRecommendationPostUrl(patientId, courseId, drugModelId)

    await axiosClient
      .post<AxiosResponse<IDosingRecommendation>>(
        url,
        {
          type: type,
          attributes: predictedAttributes,
          drugSpecificAttributes: drugSpecificAttributes
        },
        { headers }
      )
      .then(async (response) => {
        const updatedRecommendations = modelTypes.reduce<Partial<Record<TModelType, IDosingRecommendation>>>(
          (acc, curr) => {
            return {
              ...acc,
              [curr]: response.data.data
            }
          },
          {}
        )

        this.setModelPayload(updatedRecommendations, 'dosingRecommendation')

        if (modelTypes.includes('customTarget')) {
          this.setCustomTargetCalculationValid(true)
        }

        if (modelTypes.includes('customDose')) {
          this.setCustomDoseCalculationValid(true)
        }

        if (!this.nextDoseTimeOfLastCalculation) {
          this.setNextDoseTimeOfLastCalculation(nextDoseDate)
        }

        // clear errors for updated model/s
        this.setErrors(null, '', modelTypes)

        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        if (modelTypes.includes('customTarget')) {
          this.setCustomTargetCalculationValid(false)
        }

        if (modelTypes.includes('customDose')) {
          this.setCustomDoseCalculationValid(false)
        }

        this.parseModelSpecificErrors(error, modelTypes)

        this.setLoadState('updateError')

        const updatedRecommendations = modelTypes.reduce<Partial<Record<TModelType, IDosingRecommendation>>>(
          (acc, curr) => {
            return {
              ...acc,
              [curr]: null
            }
          },
          {}
        )

        this.setModelPayload(updatedRecommendations, 'dosingRecommendation')
      })
  }
}
