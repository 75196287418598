import {
  amber500,
  IColumnElement,
  Icons,
  InfoModal,
  IRowElement,
  Modal,
  PaginationPanel,
  SmartList
} from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { saveAs } from 'file-saver'
import { useEffect, useState } from 'react'

import { useHospitalStore, usePatientExternalDataStore } from '../../../../../../hooks/useStore'
import { getPageCount, paginate } from '../../../../../../utils/pagination'
import { sortAlpha } from '../../../../../../utils/smartListUtils'
import { IModalProps } from '../../../../types'
import { patientExternalDataColumns, itemsPerPage } from './constants'
import { IPatientExternalDataListItem } from '../../../../../../store/PatientExternalData/types'
import { formatToDisplayDate } from '../../../../../../utils/dates'
import { showErrorToast, showSuccessToast } from '../../../../../../shared/toast'

import './index.scss'

interface IProps extends IModalProps {
  patientId: string
  patientName: string
}

const PatientExternalDataModal: React.FC<IProps> = observer((props) => {
  const [sortColIndex, setSortColIndex] = useState(0)
  const [sortColAscending, setSortColAscending] = useState(false)
  const [sortedList, setSortedList] = useState<IRowElement[] | null>(null)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [currentPage, updateCurrentPage] = useState(1)

  const patientExternalDataStore = usePatientExternalDataStore()
  const hospitalStore = useHospitalStore()

  useEffect(() => {
    if (props.show) {
      patientExternalDataStore.fetchPatientExternalDataList(props.patientId)
    }
  }, [props.show])

  useEffect(() => {
    if (patientExternalDataStore.loadState === 'loaded') {
      const patientExternalDataList = [ ...patientExternalDataStore.patientExternalDataList.values() ]
      setTotalPages(getPageCount(patientExternalDataList.length, itemsPerPage))
      const formattedPatientExternalData = formatPatientExternalData(patientExternalDataList)
      const sortedSearchedFormattedPatientExternalData = sortAlpha(formattedPatientExternalData, sortColIndex, sortColAscending)
      setSortedList(sortedSearchedFormattedPatientExternalData)
    }
  }, [sortColAscending, patientExternalDataStore.loadState])

  const downloadPatientExternalData = async (patientExternalDataId: string) => {
    const response = await patientExternalDataStore.fetchPatientExternalData(props.patientId, patientExternalDataId)

    if (['updateError', 'loadError'].includes(patientExternalDataStore.loadState) || !response) {
      showErrorToast(patientExternalDataStore.error || 'Failed to download external patient data')

      return
    }

    // saves the file
    const reportFileResponse = JSON.stringify(response.data)
    const reportFileBlob = new Blob([reportFileResponse], { type: 'application/json' })
    // save with the filename supplied to us in double quotes from the content-disposition header
    saveAs(reportFileBlob, response.headers['content-disposition']?.match(/"([^"]+)"/)?.[1] || 'external_patient_data.json')

    showSuccessToast('External patient data downloaded')
  }

  const formatPatientExternalData = (patientExternalDataList: IPatientExternalDataListItem[]): IRowElement[] => {
    return patientExternalDataList.map((patientExternalData) => {
      const recordColumns: IColumnElement[] = [
        {
          name: 'Date & Time',
          text: patientExternalData.attributes.logged,
          element: (
            <div>
              {formatToDisplayDate(patientExternalData.attributes.logged, hospitalStore.hospital!.attributes.timezone)}
            </div>
          )
        },
        {
          name: 'Clinician',
          text: patientExternalData.attributes.clinician.name
        },
        {
          name: 'Type',
          text: patientExternalData.attributes.type,
          element: (
            <div className='patient-external-data-type'>
              {patientExternalData.attributes.type}
              <div className='patient-external-data-export'>
                <Icons.Export background={amber500}/>
              </div>
            </div>
          )
        }
      ]

      return {
        id: patientExternalData.id,
        columns: recordColumns
      }
    })
  }

  const handleSort = (colIndex: number, ascending: boolean) => {
    setSortColIndex(colIndex)
    setSortColAscending(ascending)
  }

  const handleClose = () => {
    props.setShow(false)
    updateCurrentPage(1)
  }

  const formatList = () => {
    return (
      <div className='patient-external-data'>
        <SmartList
          cols={patientExternalDataColumns(handleSort)}
          data={sortedList ? paginate(sortedList, { currentPage, itemsPerPage }) : []}
          defaultSortColumn='Date & Time'
          defaultSortDirection='desc'
          loading={patientExternalDataStore.loadState === 'loading'}
          textIfEmpty='No data to display'
          minRowsToShow={itemsPerPage}
          onActivate={downloadPatientExternalData}
        />
        {sortedList && sortedList.length > itemsPerPage && (
          <div className='patient-external-data-pagination-panel'>
            <PaginationPanel currentPage={currentPage} totalPages={totalPages} onPageChange={updateCurrentPage} />
          </div>
        )}
      </div>
    )
  }

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <div className='patient-external-data-container'>
        <InfoModal
          size='m'
          title='Export external data'
          subtitle={props.patientName}
          message={formatList()}
          onDismiss={handleClose}
        />
      </div>
    </Modal>
  )
})

export { PatientExternalDataModal }
