interface IProps {
  isLegendIcon?: boolean
  markerWidth?: number
  markerHeight?: number
  cx?: number
  cy?: number
}

export const GCSFMarker: React.FC<IProps> = (props) => {
  if (!props.isLegendIcon) {
    if (!props.cx || isNaN(props.cx)) {
      return null
    }

    if (!props.cy || isNaN(props.cy)) {
      return null
    }
  }

  const width = props.markerWidth || 10
  const height = props.markerHeight || 24

  return (
    <svg
      width={width} height={height}
      viewBox='0 0 10 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      x={props.cx ? (props.cx - (width / 2)) : undefined}
      y={props.cy ? (props.cy - (height / 2)) : undefined}
    >
      <g id='gcsf-dose-marker'>
        <circle id='Ellipse 14' cx='5' cy='9' r='3.75' fill='#438BE9' fillOpacity='0.14' stroke='#627FA3' strokeWidth='2.5' />
        <path id='Line 48 (Stroke)' fillRule='evenodd' clipRule='evenodd' d='M5 22C4.17157 22 3.5 21.3284 3.5 20.5L3.5 13.5C3.5 12.6716 4.17157 12 5 12C5.82843 12 6.5 12.6716 6.5 13.5L6.5 20.5C6.5 21.3284 5.82843 22 5 22Z' fill='#627FA3' />
      </g>
    </svg>
  )
}
