import { IDropdownItem } from '@doseme/cohesive-ui'

import { ICourseAdministrationType } from '../../../../../../../../../../store/course/types'
import { IAdministrationTypeLimits, ILimitsOverridesByAdministrationType } from '../../../../../../../../../../store/historicalSimulation/types'

export const formatAdministrations = (admins: ICourseAdministrationType[]): IDropdownItem[] => {
  const hospitalDropdownItems = admins.map((admin) => ({
    value: admin.id.toString(),
    label: admin.name
  }))

  return hospitalDropdownItems
}

export const formatNextDoseDayLimits = (min?: number, max?: number): IDropdownItem[] => {
  let returnArr = [{ value: '', label: '' }]
  if (min && max) {
    returnArr = []
    for (let i = min; i <= max; i++) {
      returnArr.push({ value: i.toString(), label: `Day ${i}` })
    }
  }

  return returnArr
}

export const getGCSFAdminLimits = (
  currentAdministrationTypeId?: string,
  GCSFLimits?: ILimitsOverridesByAdministrationType[] | null
): IAdministrationTypeLimits | null => {
  if (GCSFLimits && currentAdministrationTypeId) {
    const currentAdminType = GCSFLimits.filter((adminType) => {
      return adminType.administrationType.id === currentAdministrationTypeId
    })

    return currentAdminType.length === 1
      ? currentAdminType[0].limits
      : null
  }

  return null
}
