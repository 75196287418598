import { useState } from 'react'
import {
  CartesianGrid,
  ComposedChart,
  ReferenceDot, ReferenceLine,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { INPUT_GREY, LIGHTGREY, OCEAN_BLUE } from '@doseme/cohesive-ui'
import { CategoricalChartState } from 'recharts/types/chart/types'

import { SmartXAxisTick } from '../SmartXAxisTick'
import { SecrLabel } from './components/SecrLabel'
import { SecrOutsideRange } from './components/SecrOutsideRange'
import { getSecrPoints, xAxisTickFormatter } from '../../utils'
import { ISecrTrendData } from '../../../../types'
import { SecrTooltip } from './components/SecrTooltip'
import { IDataSetRow, ISecrDataSetRow } from '../../types'

import './index.scss'

interface IProps {
  secrTrendData: ISecrTrendData | null
  startIndex?: number
  endIndex?: number
  data: IDataSetRow[]
  secrData: ISecrDataSetRow[]
  brushContent: JSX.Element | null
  hospitalTimezone: string
}

export const SecrPlot: React.FC<IProps> = (props) => {
  const secrMax =
    props.secrTrendData?.secrObservations &&
    Math.max(...props.secrTrendData.secrObservations.map((o) => o.amount.value)) * 1.1
  const secrUnit = props.secrTrendData?.baselineAmount?.unit?.name
    ? `${props.secrTrendData?.baselineAmount?.unit?.name}`
    : ''

  const [secrTooltipPoint, setSecrTooltipPoint] = useState<IDataSetRow | undefined>()

  //This is necessary to handle the tooltip skipping x-axis values
  const secrTooltipHandler = (event: CategoricalChartState) => {
    const startOffset = props.startIndex ? props.startIndex : 0
    const index = event.activeTooltipIndex !== undefined ? event.activeTooltipIndex + startOffset : null

    if (index !== null) {
      const plotWidth = document.getElementById('secr-x-axis')?.getBoundingClientRect().width

      // plotWidth is greater than data.length then we don't need to check if there's any skipped points
      if (plotWidth && props.data.length <= plotWidth && props.data[index] !== secrTooltipPoint) {
        //Find secr point at tooltip index
        if (props.data[index].secr !== undefined) {
          setSecrTooltipPoint(props.data[index])
        } else {
          setSecrTooltipPoint(undefined)
        }
      }

      //Find range of points either side of tooltip index to include skipped points
      const interval = plotWidth ? Math.ceil(props.data.length / plotWidth) + 1 : 0
      const leftSlice = index - interval > 0 ? index - interval : 0
      const centreIndex = index - leftSlice
      const range = props.data.slice(leftSlice, index + interval + 1)

      //Find closes secr to centre of range
      let newSecrTooltipPoint: IDataSetRow | undefined
      let distanceToCentre = interval
      range.forEach((element, rangeIndex) => {
        if (element.secr && Math.abs(centreIndex - rangeIndex) < distanceToCentre) {
          distanceToCentre = Math.abs(centreIndex - rangeIndex)
          newSecrTooltipPoint = element
        }
      })

      if (newSecrTooltipPoint !== secrTooltipPoint) {
        setSecrTooltipPoint(newSecrTooltipPoint)
      }
    } else if (secrTooltipPoint) {
      setSecrTooltipPoint(undefined)
    }
  }

  const secrTooltip = () => {
    if (!secrTooltipPoint) return null
    const secrDataPoint = props.secrData.find((x) => x.time === secrTooltipPoint.time)
    if (!secrDataPoint) return null
    const amount = `${secrDataPoint.secr} ${secrUnit}`

    return (
      <ReferenceDot
        label={
          <SecrTooltip title={secrTooltipPoint.name} amount={amount} percentChange={secrDataPoint.percentChange} />
        }
        x={secrTooltipPoint.time}
        y={secrTooltipPoint.secr}
        fill='none'
        stroke='none'
      />
    )
  }

  const secrDataLastPointLabel = () => {
    const secrDataLastPoint = props.secrData[props.secrData.length - 1]
    if (!secrDataLastPoint) return

    const leftLimit = props.startIndex && props.data[props.startIndex] ?
      props.data[props.startIndex].time :
      props.data[0].time
    const rightLimit = props.endIndex && props.endIndex < props.data.length ?
      props.data[props.endIndex].time :
      props.data[props.data.length - 1].time
    let x = secrDataLastPoint.time
    let leftPointer = false
    let rightPointer = false
    if (leftLimit > secrDataLastPoint.time) {
      x = leftLimit
      leftPointer = true
    } else if (rightLimit < secrDataLastPoint.time) {
      x = rightLimit
      rightPointer = true
    }

    const amount = `${secrDataLastPoint.secr} ${secrUnit}`

    return (
      <ReferenceDot
        label={<SecrLabel amount={amount} percentChange={secrDataLastPoint.percentChange} />}
        x={x}
        y={secrDataLastPoint.secr}
        shape={<SecrOutsideRange leftPointer={leftPointer} rightPointer={rightPointer} />}
        fill={leftPointer || rightPointer ? OCEAN_BLUE : 'none'}
        stroke={leftPointer || rightPointer ? OCEAN_BLUE : 'none'}
      />
    )
  }

  const secrInfoLabels = () => {
    if (props.secrTrendData?.baselineAmount || props.secrTrendData?.highAmount || props.secrTrendData?.lowAmount) {
      return (
        <div className='secr-info-labels'>
          {props.secrTrendData?.baselineAmount && (
            <div className={'secr-info-label'}>
              <div className={'secr-info-label-title'}>Baseline</div>
              <div className='secr-info-label-value'>
                &nbsp;
                {`${props.secrTrendData?.baselineAmount.value} ${props.secrTrendData?.baselineAmount.unit?.name}`}
              </div>
            </div>
          )}
          {props.secrTrendData?.highAmount && props.secrData.length > 1 && (
            <div className={'secr-info-label'}>
              <div className={'secr-info-label-title'}>High</div>
              <div className='secr-info-label-value'>
                &nbsp;
                {`${props.secrTrendData?.highAmount.value} ${props.secrTrendData?.highAmount.unit?.name}`}
              </div>
            </div>
          )}
          {props.secrTrendData?.lowAmount && props.secrData.length > 1 && (
            <div className={'secr-info-label'}>
              <div className={'secr-info-label-title'}>Low</div>
              <div className='secr-info-label-value'>
                &nbsp;
                {`${props.secrTrendData?.lowAmount.value} ${props.secrTrendData?.lowAmount.unit?.name}`}
              </div>
            </div>
          )}
        </div>
      )
    }

    return (
      <div className='secr-info-labels'>
        <div className={'secr-info-label secr-no-info-label'}>
          <div className='secr-info-label-value'>No SeCr Recorded</div>
        </div>
      </div>
    )
  }

  //draws lines between all secr points manually and handles when they are outside the graph
  //needs to do this because of recharts limitations
  const secrLines = () => {
    let lastPoint: ISecrDataSetRow | null = null
    const topLimit = secrMax ? secrMax + 10 : 0
    const leftLimit = props.startIndex && props.data[props.startIndex] ?
      props.data[props.startIndex].time :
      props.data[0].time
    const rightLimit = props.endIndex && props.endIndex < props.data.length ?
      props.data[props.endIndex].time :
      props.data[props.data.length - 1].time

    return props.secrData.map((point, idx) => {
      if (leftLimit <= point.time && point.time <= rightLimit) {
        if (lastPoint) {
          //render and set last point
          const points = getSecrPoints(lastPoint, point, leftLimit, rightLimit, topLimit)
          lastPoint = point

          return <ReferenceLine key={`secr-line-${point.time}-${idx}`} segment={points} stroke={OCEAN_BLUE} strokeWidth={1.5} />
        }
        lastPoint = point
      }
      if (point.time < leftLimit) {
        //only set last point
        lastPoint = point
      }
      if (point.time > rightLimit && lastPoint) {
        //render and unset last point
        const points = getSecrPoints(lastPoint, point, leftLimit, rightLimit, topLimit)
        lastPoint = null

        return <ReferenceLine key={`secr-line-${point.time}-${idx}`} segment={points} stroke={OCEAN_BLUE} strokeWidth={1.5} />
      }
    })
  }

  return (
    <>
      <hr className='dosing-profile-plot-seperator' />
      <div className='dosing-profile-plot-graph-secr'>
        {secrInfoLabels()}
        <ResponsiveContainer width='99%'>
          <ComposedChart
            syncId='syncPlots'
            onMouseMove={secrTooltipHandler}
            data={props.data}
            margin={{
              top: 5,
              right: 20,
              bottom: 10,
              left: -15
            }}
          >
            <CartesianGrid stroke={LIGHTGREY} strokeWidth='1' id='secr-grid' />
            <Tooltip cursor={{ strokeDasharray: '3 3' }} />

            <XAxis
              id='secr-x-axis'
              hide={false}
              dataKey='time'
              name='name'
              type='number'
              domain={['dataMin', 'dataMax']}
              stroke={INPUT_GREY}
              // This custom tick ensures that leftmost and rightmost tick labels display within chart bounds
              tick={<SmartXAxisTick />}
              tickCount={5}
              tickFormatter={(value: number) => {
                return xAxisTickFormatter(value, props.hospitalTimezone)
              }}
            />

            <YAxis
              id='secr-y-axis'
              yAxisId={0}
              allowDataOverflow={false}
              dataKey='secr'
              unit=''
              type='number'
              // using secr max keeps it consistent and stops the lines intersecting the top y axis (if it adjusts it needs to be calculated for each adjustment)
              domain={[0, secrMax ? secrMax : 'dataMax']}
              scale='linear'
              label={{
                value: secrUnit ? `SeCr (${secrUnit})` : 'SeCr',
                position: 'inside',
                angle: -90,
                dx: -10,
                fontWeight: 'bold'
              }}
              stroke={INPUT_GREY}
              tick={{ stroke: '#444', fill: '#444' }}
              orientation='left'
              padding={{ bottom: 0, top: 25 }}
            />

            <Scatter name='Secr' dataKey='secr' fill={OCEAN_BLUE} isAnimationActive={false}></Scatter>

            {props.data.length && secrLines()}

            {secrDataLastPointLabel()}

            {secrTooltip()}

            {props.brushContent}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}
