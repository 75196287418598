import { format } from 'date-fns'
import moment from 'moment'

import { dateFnsRawDateOnly, rawTimeOnly } from '../../../../../../../../constants/timeFormat'
import { ICustomDoseSimulationAttributes } from '../../../../../../../../store/dosingRecommendation/types'
import { ICustomDoseSimulationLimits } from '../../../../../../../../store/historicalSimulation/types'
import { IFormState } from '../../../../../../../../types/validation'

export const buildDocetaxelCustomDoseAttributes = (
  form: IFormState,
  nextDoseAt: Date,
  hospitalTimezone: string,
  limits?: ICustomDoseSimulationLimits
): ICustomDoseSimulationAttributes => {
  return {
    targetType: 'simulation',
    nextDoseDate: moment
      .tz(format(nextDoseAt, dateFnsRawDateOnly) + 'T' + format(nextDoseAt, rawTimeOnly), hospitalTimezone)
      .utc()
      .toISOString(),
    doseAmount: {
      value: parseFloat(form.values['dose']),
      unit: limits?.doseAmount.min.unit
    },
    infusionLength: parseFloat(form.values['infusionLength']),
    dosingInterval: {
      value: form.values['dosingInterval'],
      unit: limits?.dosingInterval.min.unit
    },
    numberOfDoses: 1 //FIXME - Docetaxel is limited to 1 dose for now
  }
}
