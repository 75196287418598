import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { InfoModal, Modal, Button, Dropdown, TextInput } from '@doseme/cohesive-ui'

import { useFormValidation } from '../../../../../../../../../../hooks/useFormValidation'
import { IFormField, IFormState } from '../../../../../../../../../../types/validation'
import { IModalProps } from '../../../../../../../../types'
import { IGCSFScheduleParts } from '../../../../../../../../../../store/dosingRecommendation/types'
import { stringToFloat } from '../../../../../../../../../../utils/validation/formatters'
import { isRequired, isStringValidNumber, isLessThanFourDecimalPlaces, isStringWithinNumericLimits } from '../../../../../../../../../../utils/validation/rules'
import { ICourseAdministrationType } from '../../../../../../../../../../store/course/types'
import { formatAdministrations, getGCSFAdminLimits, formatNextDoseDayLimits } from './utils'
import { ILimitsOverridesByAdministrationType } from '../../../../../../../../../../store/historicalSimulation/types'

interface IProps extends IModalProps {
  calculateCustomDose: (form: IFormState) => void
  GCSFData?: IGCSFScheduleParts
  adminTypes?: ICourseAdministrationType[]
  GCSFLimits?: ILimitsOverridesByAdministrationType[] | null
}

export const SimulatedGCSFForm: React.FC<IProps> = observer((props) => {
  const [blur, setBlur] = useState<boolean>(false)
  const [drugId, setDrugId] = useState<string | undefined>(props.GCSFData?.administrationType.id)

  const adminLimits = getGCSFAdminLimits(drugId, props.GCSFLimits)

  const formFields: Record<string, IFormField> = useMemo(() => {
    return {
      drug: {
        initialInput: drugId,
        rules: [isRequired]
      },
      numberOfDoses: {
        initialInput: props.GCSFData?.numberOfDoses || '',
        initialConstraints: adminLimits?.numberOfDoses,
        rules: [isRequired, isStringValidNumber, isLessThanFourDecimalPlaces, isStringWithinNumericLimits],
        formatter: stringToFloat
      },
      nextDoseDay: {
        initialInput: props.GCSFData?.nextDoseDay.toString() || '',
        rules: [isRequired]
      },
      amount: {
        initialInput: props.GCSFData?.amount.value || '',
        initialConstraints: adminLimits?.doseClinical,
        rules: [isRequired, isStringValidNumber, isLessThanFourDecimalPlaces, isStringWithinNumericLimits],
        formatter: stringToFloat
      }
    }
  }, [props.GCSFData, props.GCSFLimits, drugId])

  const form = useFormValidation(formFields)

  useEffect(() => {
    form.reset()
  }, [drugId])

  const formContent = (): JSX.Element => {
    if (props.adminTypes) {
      const adminLimits = getGCSFAdminLimits(drugId, props.GCSFLimits)

      return (
        <>
          <div>
            <Dropdown
              label='Drug:'
              fieldState={form.getValidState('drug')}
              validationText={form.getValidationMsg('drug')}
              data={formatAdministrations(props.adminTypes)}
              value={drugId}
              onSelect={(item) => {
                setBlur(true)
                form.validateFields(
                  [
                    {
                      field: 'drug',
                      input: item.value
                    }
                  ],
                  'updateFieldsDisplay'
                )
                setDrugId(item.value)
              }}
              placeholder='Select an item'
            />
          </div>
          <div>
            <TextInput
              label='Number of doses:'
              fieldState={blur ? 'valid' : form.getValidState('numberOfDoses')}
              validationText={blur ? '' : form.getValidationMsg('numberOfDoses')}
              required={true}
              value={form.inputs['numberOfDoses']}
              onChange={(value: string) =>
                form.validateFields([
                  {
                    field: 'numberOfDoses',
                    input: value,
                    constraints: adminLimits?.numberOfDoses
                  }
                ])
              }
              onBlur={() => {
                setBlur(false)
                form.updateFieldsDisplay(['numberOfDoses'])
              }}
              name='number-of-doses-input'
              disabled={!adminLimits}
            />
          </div>
          <div>
            <Dropdown
              label='Start GCSFs on:'
              fieldState={form.getValidState('nextDoseDay')}
              validationText={form.getValidationMsg('nextDoseDay')}
              data={formatNextDoseDayLimits(adminLimits?.nextDoseDay.min.value, adminLimits?.nextDoseDay.max.value)}
              value={form.inputs['nextDoseDay']}
              onSelect={(item) =>
                form.validateFields(
                  [
                    {
                      field: 'nextDoseDay',
                      input: item.value
                    }
                  ],
                  'updateFieldsDisplay'
                )
              }
              placeholder='Select an item'
              disabled={!adminLimits}
            />
          </div>
          <div>
            <TextInput
              label='Dose amount:'
              fieldState={blur ? 'valid' : form.getValidState('amount')}
              validationText={blur ? '' : form.getValidationMsg('amount')}
              required={true}
              value={form.inputs['amount']}
              onChange={(value: string) =>
                form.validateFields([
                  {
                    field: 'amount',
                    input: value,
                    constraints: adminLimits?.doseClinical
                  }
                ])
              }
              onBlur={() => {
                setBlur(false)
                form.updateFieldsDisplay(['amount'])
              }}
              units={adminLimits?.doseClinical.default.unit?.name}
              name='dose-amount-input'
              disabled={!adminLimits}
            />
          </div>
        </>
      )
    }

    return <></>
  }

  return (
    <Modal show={props.show}>
      <InfoModal
        size='s'
        linkComponent={
          <>
            <Button
              className='mr-3'
              data-testid='cancel-btn'
              onClick={() => props.setShow(false)}
              variant='primary-outline'
            >
              Cancel
            </Button>
            <Button
              disabled={!form.valid}
              onClick={() => {
                props.setShow(false)
                props.calculateCustomDose(form)
              }}
              variant='primary'
            >
              Continue
            </Button>
          </>
        }
        title='Simulate GSCF doses'
        message={<div className='position-relative w-100'>{formContent()}</div>}
      />
    </Modal>
  )
})
