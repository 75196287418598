import { IDateState, ITimeState } from '@doseme/cohesive-ui'
import { format } from 'date-fns'

import { dateFnsRawDateOnly } from '../../constants/timeFormat'
import { IObservationType } from '../../store/observations/types'
import { IUnit } from '../../store/types'
import { heightUnits, weightUnits } from '../../test/factories/mockDisplay'
import { TFieldFormatter } from '../../types/validation'
import { ICourseAdministrationType } from '../../store/course/types'

export const stringToInteger: TFieldFormatter = (input: string): number => {
  return parseInt(input, 10)
}

export const stringToFloat: TFieldFormatter = (input: string): number => {
  return parseFloat(input)
}

export const stringUnitIdToWeightUnit: TFieldFormatter = (input: string): IUnit => {
  return weightUnits[input]
}

export const stringUnitIdToHeightUnit: TFieldFormatter = (input: string): IUnit => {
  return heightUnits[input]
}

export const dateToISODateString: TFieldFormatter = (input: Date): string => {
  return format(input, dateFnsRawDateOnly)
}

export const dateStateToISODateString: TFieldFormatter = (input: IDateState): string => {
  return `${input?.yyyy || 'yyyy'}-${input?.mm || 'mm'}-${input?.dd || 'dd'}`
}

export const timeStateToTimeString: TFieldFormatter = (input: ITimeState): string => {
  return `${input.hh}:${input.mm}`
}

export const stringObsTypeIdToObsType: TFieldFormatter = (
  input: string,
  formatterConstraints: Map<string, IObservationType>
): IObservationType | null => {
  return formatterConstraints.get(input) || null
}

export const stringAdminTypeIdToAdminType: TFieldFormatter = (
  input: string,
  formatterConstraints: Map<string, ICourseAdministrationType>
): ICourseAdministrationType | null => {
  return formatterConstraints.get(input) || null
}
