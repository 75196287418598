import { ControlButton, Icons, InfoBubble, IStatistic, LOADING_GREY, StatisticsPanel, TextInput } from '@doseme/cohesive-ui'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'

import { useFormValidation } from '../../../../../../../../../hooks/useFormValidation'
import { ICustomDoseSimulationLimits } from '../../../../../../../../../store/historicalSimulation/types'
import { IFormField, IFormState } from '../../../../../../../../../types/validation'
import { stringToFloat } from '../../../../../../../../../utils/validation/formatters'
import {
  isLessThanFourDecimalPlaces,
  isRequired,
  isStringValidNumber,
  isStringWithinNumericLimits
} from '../../../../../../../../../utils/validation/rules'
import { SimulationOutcomesInfoIcon } from '../../SimulationPanel/components/SimulationOutcomesInfoIcon'
import { ValidationErrorsDisplay } from '../../SimulationPanel/components/ValidationErrorsDisplay'

import './index.scss'

interface IProps {
  limits?: ICustomDoseSimulationLimits
  calculated: boolean
  loading: boolean
  disabled?: boolean
  setCalculated: (calculated: boolean) => void
  calculateCustomDose: (form: IFormState) => void
}

export const SimulationForm: React.FC<IProps> = observer((props) => {
  const formFields: Record<string, IFormField> = useMemo(() => {
    return {
      dose: {
        initialInput: props.limits?.doseAmount.default?.value.toString(),
        initialConstraints: props.limits?.doseAmount,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      infusionLength: {
        initialInput: props.limits?.infusionLength.default?.value.toString(),
        initialConstraints: props.limits?.infusionLength,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      },
      dosingInterval: {
        initialInput: props.limits?.dosingInterval.default?.value.toString(),
        initialConstraints: props.limits?.dosingInterval,
        rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
        formatter: stringToFloat
      }
    }
  }, [props.limits])

  const form = useFormValidation(formFields)

  useEffect(() => {
    form.reset()
  }, [props.limits])

  const simulationFormFields = (): IStatistic[] => {
    const errorMap = form.getErrorMap()
    const errorOrder = Array.from(errorMap.keys())

    return [
      {
        id: 'dose',
        title: 'Docetaxel Dose',
        subtitle: props.limits?.doseAmount.min.unit?.name,
        value: (
          <div className='custom-field-invalid-wrapper'>
            {errorMap.get('dose') ? (
              <div className='custom-field-invalid-indicator validation-error-number'>
                {errorOrder.indexOf('dose') + 1}
              </div>
            ) : null}
            <TextInput
              borderRadius={8}
              className='customdose-dose-form-field'
              textAlignCenter
              fieldState={form.getValidState('dose')}
              value={form.inputs['dose']}
              onChange={(value) =>
                form.validateFields([
                  {
                    field: 'dose',
                    input: value,
                    constraints: formFields.dose.initialConstraints
                  }
                ])
              }
              onBlur={() => {
                form.updateFieldsDisplay(['dose'])
                props.setCalculated(false)
              }}
              disabled={props.disabled}
            />
          </div>
        )
      },
      {
        id: 'infusionLength',
        title: 'Infusion Length',
        subtitle: props.limits?.infusionLength.min.unit?.name,
        value: (
          <div className='custom-field-invalid-wrapper'>
            {errorMap.get('infusionLength') ? (
              <div className='custom-field-invalid-indicator validation-error-number'>
                {errorOrder.indexOf('infusionLength') + 1}
              </div>
            ) : null}
            <TextInput
              borderRadius={8}
              className='customdose-dose-form-field'
              textAlignCenter
              fieldState={form.getValidState('infusionLength')}
              value={form.inputs['infusionLength']}
              onChange={(value) =>
                form.validateFields([
                  {
                    field: 'infusionLength',
                    input: value,
                    constraints: formFields.infusionLength.initialConstraints
                  }
                ])
              }
              onBlur={() => {
                form.updateFieldsDisplay(['infusionLength'])
                props.setCalculated(false)
              }}
              disabled={props.disabled}
            />
          </div>
        )
      },
      {
        id: 'dosingInterval',
        title: 'Cycle length',
        subtitle: props.limits?.dosingInterval.min.unit?.name,
        value: (
          <div className='custom-field-invalid-wrapper'>
            {errorMap.get('dosingInterval') ? (
              <div className='custom-field-invalid-indicator validation-error-number'>
                {errorOrder.indexOf('dosingInterval') + 1}
              </div>
            ) : null}
            <TextInput
              borderRadius={8}
              className='customdose-dose-form-field'
              textAlignCenter
              fieldState={form.getValidState('dosingInterval')}
              value={form.inputs['dosingInterval']}
              onChange={(value) =>
                form.validateFields([
                  {
                    field: 'dosingInterval',
                    input: value,
                    constraints: formFields.dosingInterval.initialConstraints
                  }
                ])
              }
              onBlur={() => {
                form.updateFieldsDisplay(['dosingInterval'])
                props.setCalculated(false)
              }}
              disabled={props.disabled}
            />
          </div>
        )
      }
    ]
  }

  const calculateButton = () => {
    if (props.loading) {
      return (
        <Icons.ThinSpinner strokeWidth={12} r={32} stroke={LOADING_GREY} width='64px' />
      )
    }

    if (!props.disabled && props.calculated) {
      return (
        <InfoBubble type='info' bubbleTitle='View simulated cycle above.' >
          <p className='custom-docetaxel-calculate-info-text'>
            You can also simulate GCSF doses below.
          </p>
        </InfoBubble>
      )
    }

    return (
      <ControlButton
        icon={faChevronRight}
        iconRight
        onClick={() => props.calculateCustomDose(form)}
        disabled={!form.valid || props.disabled}
      >
        <div className='pl-2'>Calculate</div>
      </ControlButton>
    )
  }

  return (
    <div>
      <div className='d-flex justify-content-between'>
        <div className='flex-column mr-2'>
          <div className='d-flex align-items-center'>
            <span className='sim-panel-subheading font-bold co-h6'>
              Customized cycle simulation
            </span>
          </div>
          <div className='sim-panel-calculated-subtitle'>Enter details below</div>
          <div className='custom-form-wrapper custom-docetaxel-sub-tab'>
            <StatisticsPanel
              data={simulationFormFields()}
              showDividers={false}
            />
          </div>
        </div>
        <div className='flex-column w-100 ml-4'>
          <div className='d-flex align-items-center'>
            <span className='sim-panel-subheading font-bold co-h6'>
              Predicted Outcomes
              <div className='info-icon'>
                <SimulationOutcomesInfoIcon />
              </div>
            </span>
          </div>
          <div className='sim-panel-calculated-subtitle'>Calculated by DoseMeRx</div>
          <div className='custom-docetaxel-calculate' >
            {calculateButton()}
          </div>
        </div>
      </div >
      <div className='mt-3'>
        <ValidationErrorsDisplay validationErrors={Array.from(form.getErrorMap())} />
      </div>
    </div>
  )
})
