import { Patient } from '../../../../../../../../store/patient/Patient'
import { formatToDisplayDate } from '../../../../../../../../utils/dates'
import { IPatientInfo } from './types'
import { getPatientDisplayName } from '../../../PatientList/utils'

export const getPatientInfo = (selectedPatient: Patient | null, timezone?: string): IPatientInfo => {
  if (!selectedPatient) {
    return {
      name: 'Select a Patient...',
      dob: '-',
      weight: '-',
      height: '-'
    }
  }

  const dobDateFormat = window.env.DOB_DATE_FORMAT || 'MM/DD/YYYY'
  const dob = timezone ? formatToDisplayDate(
    selectedPatient.attributes.dob,
    timezone,
    dobDateFormat
  ) + ' ' + `(${selectedPatient.attributes.age.value} ${selectedPatient.attributes.age.unit?.name})` : '-'

  return {
    name: getPatientDisplayName(selectedPatient.attributes.familyName, selectedPatient.attributes.givenNames),
    dob: dob,
    weight: selectedPatient.attributes.weight.unit?.name && selectedPatient.attributes.weight.value
      ? selectedPatient.attributes.weight.value + selectedPatient.attributes.weight.unit?.name
      : '—',
    height: selectedPatient.attributes.height.unit?.name && selectedPatient.attributes.height.value
      ? selectedPatient.attributes.height.value + selectedPatient.attributes.height.unit?.name
      : '—'
  }
}
