import { makeAutoObservable, observable, ObservableMap } from 'mobx'
import moment from 'moment-timezone'

import axiosClient, { AxiosError, AxiosResponse } from '../../utils/axiosClient'
import {
  adminBulkExcludeUrl,
  adminBulkIncludeUrl,
  adminDeleteUrl,
  adminPostUrl,
  adminPutUrl,
  adminsGetUrl
} from '../../constants/api'
import { RootStore } from '../RootStore'
import { IAmount, TLoadState } from '../types'
import { Administration } from './Administration'
import { IAdministration, IMolecule, IPostAdministration } from './types'

export interface IFirstLastDoseDates {
  first: string | null
  last: string | null
}

export class AdministrationsStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  administrations: ObservableMap<string, Administration> = observable.map({}, { deep: false })

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  // Remove a single administration from the store after deleting
  removeSingleAdministration(id: string) {
    this.administrations.delete(id)
  }

  // Create-or-update. Does not delete.
  setAdministrations(admins: IAdministration[]) {
    admins.forEach((admin) => this.administrations.set(admin.id, new Administration(this, admin)))
  }

  resetAdministrations(admins: IAdministration[]) {
    this.administrations = observable.map({}, { deep: false })
    admins.forEach((admin) => this.administrations.set(admin.id, new Administration(this, admin)))
  }

  resetStore() {
    this.administrations = observable.map({}, { deep: false })
    this.setLoadState('initial')
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  get firstLastDoseDates() {
    const admins = [...this.administrations.values()]
    if (admins.length) {
      const adminsSorted = admins.sort((a, b) => {
        if (a.attributes.administeredAt.value === b.attributes.administeredAt.value) {
          return 0
        }

        return a.attributes.administeredAt.value < b.attributes.administeredAt.value ? -1 : 1
      })

      return {
        first: moment(adminsSorted[0].attributes.administeredAt.value).toISOString(),
        last: moment(adminsSorted[adminsSorted.length - 1].attributes.administeredAt.value).toISOString()
      }
    }

    return {
      first: null,
      last: null
    }
  }

  // THIS IS TEMPORARY until new implementation of duplicates
  get hasDuplicates() {
    const admins = [...this.administrations.values()]

    return !!admins.filter((item) => item.attributes.duplicate && !item.attributes.excludeFromCalculations).length
  }

  async fetchAdminsForPatientCourse(patientId: string, courseId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IAdministration[]>>(adminsGetUrl(patientId, courseId), { headers })
      .then((response: AxiosResponse) => {
        this.resetAdministrations(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
        this.setAdministrations([])
      })
  }

  async createAdministrations(
    patientId: string,
    courseId: string,
    administrations: IPostAdministration[]
  ): Promise<IAdministration | null> {
    this.setLoadState('updating')
    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    const data = administrations.map((admin) => {
      return {
        type: 'administration',
        attributes: admin
      }
    })

    return await axiosClient
      .post<AxiosResponse<IAdministration>>(
        adminPostUrl(patientId, courseId),
        data,
        { headers }
      )
      .then((response: AxiosResponse) => {
        this.setLoadState('loaded')

        return response.data.data
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)

        return null
      })
  }

  async editAdministration(
    adminId: string,
    patientId: string,
    courseId: string,
    molecule: IMolecule,
    amount: IAmount,
    infusionLength: number | null,
    administeredAt: string,
    excludeFromCalculations: boolean
  ): Promise<IAdministration | null> {
    this.setLoadState('updating')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    return await axiosClient
      .put<AxiosResponse<IAdministration>>(
        adminPutUrl(patientId, courseId, adminId),
        {
          id: adminId,
          type: 'administration',
          attributes: {
            molecule,
            amount,
            infusionLength,
            administeredAt,
            excludeFromCalculations
          }
        },
        { headers }
      )
      .then((response: AxiosResponse) => {
        this.setAdministrations([response.data.data])
        this.setLoadState('loaded')

        return response.data.data
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)

        return null
      })
  }

  async deleteAdministration(
    patientId: string,
    courseId: string,
    adminId: string
  ): Promise<IAdministration | null> {
    this.setLoadState('updating')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .delete<AxiosResponse<IAdministration>>(adminDeleteUrl(patientId, courseId, adminId), { headers })
      .then(() => {
        this.removeSingleAdministration(adminId)
        this.setLoadState('loaded')

        return null
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)

        return null
      })

    return null
  }

  async bulkIncludeAdministration(patientId: string, courseId: string, adminsitrationIds: number[]) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .put<AxiosResponse<IAdministration[]>>(adminBulkIncludeUrl(patientId, courseId),
        { administrationIds: adminsitrationIds },
        { headers })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          this.setAdministrations(response.data.data)
        }
        this.setLoadState('loaded')

        return response.data.data
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)

        return null
      })
  }

  async bulkExcludeAdministration(patientId: string, courseId: string, adminsitrationIds: number[]) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .put<AxiosResponse<IAdministration[]>>(adminBulkExcludeUrl(patientId, courseId),
        { administrationIds: adminsitrationIds },
        { headers })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          this.setAdministrations(response.data.data)
        }
        this.setLoadState('loaded')

        return response.data.data
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)

        return null
      })
  }
}
