export const getMax = (currentValue: number, newValue: number) => {
  return (newValue > currentValue)
    ? newValue
    : currentValue
}

export const getNonZeroMin = (currentValue: number, newValue: number) => {
  return (newValue && newValue < currentValue)
    ? newValue
    : currentValue
}
