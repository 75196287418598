interface IProps {
  isLegendIcon?: boolean
  markerWidth?: number
  markerHeight?: number
  cx?: number
  cy?: number
}

export const WBCMarker: React.FC<IProps> = (props) => {
  if (!props.isLegendIcon) {
    if (!props.cx || isNaN(props.cx)) {
      return null
    }

    if (!props.cy || isNaN(props.cy)) {
      return null
    }
  }

  const width = props.markerWidth || 16
  const height = props.markerHeight || 16

  return (
    <svg
      width={width}
      height={height}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      x={props.cx ? (props.cx - (width / 2)) : undefined}
      y={props.cy ? (props.cy - (height / 2)) : undefined}
    >
      <g id='docetaxel-WBC-result-marker'>
        <path id='diamond' d='M6 0L10 6L6 12L2 6L6 0Z' fill='#1E2430' />
      </g>
    </svg>
  )
}
